 .p-progressbar .p-progressbar-label{
    color: black !important;
    font-weight: bold;
}


.contenedor-secciones {
    height: 60vh;
	overflow-y: auto;
	overflow-x: hidden;
}

@media(min-width: 1300px){
    .contenedor-secciones {
        height: 60vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media(min-width: 1440px){
    .contenedor-secciones {
        height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media(min-width: 1920px){
    .contenedor-secciones {
        height: 55vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.contenedor-secciones::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.contenedor-secciones::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.contenedor-secciones::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-secciones::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

.contenedor-secciones::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
}

.contenedor-secciones::-webkit-scrollbar-track:hover,
.contenedor-secciones::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}


.boton-lecciones{
    background-color: #ff9900;
}


.centrar-reproductor{
    display: flex;
    flex-direction: column;
}