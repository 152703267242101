/* .control-asesorias-card {
    height: 200px;
    width: 250px;
} */

span.p-highlight {
    color: #000!important;
    background-color: #ffcc00!important;
}

@media (min-width: 768px) {
    .control-asesorias-card {
        /* height: 250px; */
        width: 300px;
    }
}