.pill-checkbox {
	cursor: pointer;
	display: inline-block;
	float: left;
	font-size: 14px;
	font-weight: normal;
	line-height: 20px;
	margin: 0 12px 12px 0;
	text-transform: capitalize;
}

.pill-checkbox input[type="checkbox"] {
	display: none;
}

.pill-checkbox input[type="checkbox"]:checked + .pill-checkbox-label {
	background-color: #ff9900;
	border: 1px solid #ff9900;
	color: #000;
	padding-right: 16px;
	padding-left: 16px;
}

.pill-checkbox-label {
	border: 1px solid #ff9900;
	border-radius: 20px;
	color: var(--body-color);
	display: block;
	padding: 7px 28px;
	text-decoration: none;
}

.pill-checkbox input[type="checkbox"]:checked + .pill-checkbox-label .pill-checkbox-icon-checkLight {
	display: inline-block;
}

.pill-checkbox input[type="checkbox"]:checked + .pill-checkbox-label .pill-checkbox-icon-addLight,
.pill-checkbox-label .pill-checkbox-icon-checkLight,
.pill-checkbox-children {
	display: none;
}

.pill-checkbox-label .pill-checkbox-icon {
	width: 12px;
	height: 12px;
	margin: 0 0 0 12px;
}

.pill-checkbox-icon-smallest {
	font-size: 12px;
	line-height: 12px;
}

.pill-checkbox-icon {
	background: transparent;
	display: inline-block;
	font-style: normal;
	vertical-align: baseline;
	position: relative;
}