.css-1te4bqa{
	text-decoration: none !important;
}


.css-1ctr4wy a {
	text-decoration: none !important;
}

.css-1te4bqa a{
	text-decoration: none !important;
}

.css-ra3y5v {
	text-decoration: none !important;
}

.css-1ogwtt2{
	text-decoration: none !important;
}

.p-badge.notificacion-foroayuda {
	height: 0.5rem!important;
	min-width: 0.5rem!important;
}

.sidebar-scroll.sidebar {
	border-right: none!important;
}