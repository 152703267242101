.timer-wrapper {
	display: flex;
	justify-content: center;
}

.time-wrapper {
	position: relative;
	width: 80px;
	height: 60px;
	font-size: 48px;
	/* font-family: "Montserrat"; */
}

.time-wrapper .time {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(0);
	opacity: 1;
	transition: all 0.2s;
}

.time-wrapper .time.up {
	opacity: 0;
	transform: translateY(-100%);
}

.time-wrapper .time.down {
	opacity: 0;
	transform: translateY(100%);
}

/* .btn-play {
  border-radius: 100%;
  width: 250px;
  height: 250px;
  border-color: #fd5900;
} */

.btn-play:hover {
	background-color: #cadcee;
	/* border-color: #6c757d;	 */
}
