.fondo-mos {
	background-image: url("@assets/images/cursoMos.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	color: white;

	margin-bottom: 1rem;

	border-radius: 10px;
}

.fondo-mos.agenda-asesoria {
	background-image: url("@assets/images/bg-agendas-asesorias.jpg")!important;
}

.fondo-propedeutico {
	height: 200px;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.505);
}



@media screen and (max-width: 991px) {
	.customized-timeline .p-timeline-event:nth-child(even) {
		flex-direction: row !important;
	}
	.customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
		text-align: left !important;
	}
	.customized-timeline .p-timeline-event-opposite {
		flex: 0;
	}
	.customized-timeline .p-card {
		margin-top: 1rem;
	}
}

.p-datepicker:not(.p-datepicker-inline) {
	z-index: 1500 !important;
}
.calendario .p-button {
	background-color: #00152a !important;
	border: 1px solid #00152a;
}

.calendario .p-button:hover {
	border: 1px solid #00152a !important;
}

.btn-certiport:hover {
	background-color: #d97706 !important;
}
.scrollTop {
	z-index: 1300;
}
.scrollTop:hover {
	background-color: #d97706 !important;
}

.btn-prime {
	background-color: #007bff !important;
	border: 1px solid #007bff !important;
}

.btn-prime:hover {
	background-color: #0069d9 !important;
	border: 1px solid #0062cc !important;
}

.contenedor-lecciones {
	padding-top: 1rem;
	height: 25vh;
	width: 100%;
	background: var(--contenedor-lecciones-mos);

	overflow-y: scroll;
	overflow-x: hidden;
}

.contenedor-lecciones::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-lecciones::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-lecciones::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-lecciones::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-lecciones::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-lecciones::-webkit-scrollbar-track:hover,
.contenedor-lecciones::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.contenedor-proyectos {
	padding-top: 1rem;
	height: 25vh;
	width: 100%;
	background: var(--contenedor-lecciones-mos);

	overflow-y: scroll;
	overflow-x: hidden;
}

.contenedor-proyectos::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-proyectos::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-proyectos::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-proyectos::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-proyectos::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-proyectos::-webkit-scrollbar-track:hover,
.contenedor-proyectos::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

@media (min-width: 2000px) {
	.fondo-mos {
		height: 400px !important;
	}
	.fondo-propedeutico {
		height: auto;
	}
}

@media (min-width: 4000px) {
	.fondo-mos {
		height: 600px !important;
	}
}
