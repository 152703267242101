.carousel-control {
	background-color: transparent;
	border: none;
}

.mini-slider-button {
	width: 5% !important;
	height: 100%;
}

.mini-slider-button:hover {
	background-color: #4744445c !important;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.card-img-course {
	width: 286px;
	height: 180px;
	border-radius: 25px;
	object-fit: cover;
}

.card-img-hover-home {
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.card-img-hover-home:hover {
	transform: scale(1.1);
}

.contenido .contenido-overlay {
	background: rgba(0, 0, 0, 0.7);
	position: absolute;
	height: 99%;
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	-webkit-transition: all 0.4s ease-in-out 0s;
	-moz-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
}

.content-image {
	width: 100%;
}

.contenido:hover .contenido-overlay {
	opacity: 1;
}

.contenido-details {
	position: absolute;
	text-align: center;
	padding-left: 1em;
	padding-right: 1em;
	width: 100%;
	top: 50%;
	left: 50%;
	opacity: 0;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.contenido:hover .contenido-details {
	top: 50%;
	left: 50%;
	opacity: 1;
}

.fadeIn-bottom {
	top: 80%;
}

.img-slider {
	margin: 0 auto;
	margin-bottom: 10rem;
}

.contenedor-mos {
	background-image: url("@assets/images/recursos_mos/contenido-mos.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: white;
	padding: 0;
	border-radius: 10px;
	height: 100%;
}

.contenedor-feedback-inicio {
	background-image: url("@assets/images/formRegistro.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: #000;
	padding: 0;
	border-radius: 15px;
	height: 100%;
}

.contenedor-mos.invitado {
	height: 110%!important;
}

.contenedor-carrusel {
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.705);
	display: flex;
	flex-direction: column;
	height: 100%;
	width: auto;
	border-radius: 10px;
}

.contenido-carrusel {
	height: 100% !important;
}

.carousel {
	height: 100%;
}

.carousel-root {
	height: 100%;
}

.carousel .control-dots {
	margin: 0px !important;
}

.carousel .control-dots .dot {
	box-shadow: none !important;
	background: #ff9900 !important;
	width: 16px !important;
	height: 16px !important;
}

/* .carousel .slide img {
	width: 100%;
	height: 100%;
} */

.img-slider {
	margin: 0 auto;
}

.image-wrapper {
	width: 170px;
	height: auto;
	margin-bottom: 10px;
	padding-top: 1rem;
}

.card-cursos {
	position: relative;
	width: 300px;
	height: 220px;
	overflow: hidden;
	margin: 20px;
	border-radius: 10px;
}

.card-image {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.card-image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition: transform 0.5s ease;
}

.card-image:hover img {
	transform: scale(1.3);
}

.card-text {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 20px;
	box-sizing: border-box;
	transform: translateY(100%);
	transition: transform 0.5s ease;
	height: 100%;
}

.card-image:hover .card-text {
	transform: translateY(0);
}

.parallax {
	background-image: url("@assets/images/capacitacion.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	padding: 0;
	border-radius: 10px;
}

.contenedor-feedback-fondo {
	background-image: url("@assets/images/feedback.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	padding: 0;
	border-radius: 10px;
}

.text-container {
	height: 100%;
	width: 100%;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.505);
}

.parallax-text {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #fff;
}

.columna-capacitacion,
.columna-feedbacks,
.columna-mos {
	height: 30rem;
}

.feedbacks-container {
	height: 24em;
	/* overflow: scroll; */
}

@media (max-width: 375px) {
	.columna-mos {
		height: 20rem;
	}

	.columna-capacitacion {
		height: 20rem;
	}
}

@media (min-width: 376px) and (max-width: 425px) {
	.columna-mos {
		height: 30rem;
	}

	.columna-capacitacion {
		height: 30rem;
	}
	.contenedor-lordicons {
		margin-top: 0rem;
	}
}

@media (min-width: 425px) and (max-width: 768px) {
	.contenedor-lordicons {
		margin-top: 1rem;
	}
	.columna-mos {
		height: 20rem;
	}

	.columna-capacitacion {
		height: 20rem;
	}
}

@media (min-width: 1440px) {
	.columna-mos {
		height: 30rem;
	}

	.columna-capacitacion {
		height: 30rem;
	}
}

/*
.contenido-mos img{
	width: 300px ;
} */

.carousel .slide img {
	width: 80% !important;
}
.lectura-veloz .carousel .slide img {
	width: 6	0%!important;
}

@media(min-width: 768px) and (max-width:992px){
	.carousel .slide img {
		width: 50% !important;
	}
}

@media(min-width: 1024px) and (max-width: 1550px){
	.carousel .slide img {
		width: 85% !important;
	}
}

@media(min-width: 1551px) {
	.carousel .slide img {
		width: 65% !important;
	}
}




@media (min-width: 1300px) {
	.image-wrapper {
		width: 230px !important;
	}
}

@media (max-width: 768px){
	.podria-interesar{
		height: 100vh !important;
	}
	.columna-mos{
		height: auto;
	}
}

@media(min-width: 768px) and (max-width:990px){
	.columna-mos{
		margin-bottom: 3rem;
	}
}

@media (min-width: 2500px) {
	/* .filaCursos {
		margin-top: 25rem !important;
	} */

	.contenedor-mos {
		height: 100%;
	}
	.image-wrapper {
		width: 490px !important;
	}
	.slide-subtitle {
		font-size: 2rem;
	}
	.podria-interesar {
		height: 40rem;
		margin-bottom: 12rem!important;
	}
	.contenedor-material-capacitacion {
		height: 100%;
	}

	.card-cursos {
		width: 600px;
		height: 450px;
	}

	.scroll-top span {
		width: 100px !important;
		height: 100px !important;
	}
	.scroll-top span a svg {
		margin-top: 1rem;
		font-size: 4.2rem !important;
	}

	.card-text {
		font-size: 2.1rem;
	}

	.ver-mas-rutas {
		font-size: 2.2rem;
	}

	.ver-mas-rutas a {
		font-size: 2.2rem;
	}
	/* .contenedor-atajos{
		padding-top: 11rem !important;
	} */

	.columna-mos {
		height: 50rem;
	}

	.columna-capacitacion {
		height: 50rem;
	}

	/* .contenedor-lordicons {
		margin-top: 20rem;
	} */

	.popover-categoria_titulo{
		font-size: 2rem!important;
	}

	.popover-categoria_descripcion{
		font-size: 1.8rem!important;
	}
}

@media (min-width: 4000px) {
	.image-wrapper {
		width: 490px;
	}
	.slide-subtitle {
		font-size: 2rem;
	}
	.podria-interesar {
		height: 55rem;
		margin-bottom: 24rem!important;
	}
	.contenedor-material-capacitacion {
		height: 100%;
	}

	.card-cursos {
		width: 900px;
		height: 550px;
	}

	.scroll-top span {
		width: 200px !important;
		height: 200px !important;
	}
	.scroll-top span a svg {
		margin-top: 2rem;
		font-size: 7.8rem !important;
	}

	.card-text {
		font-size: 2.1rem;
	}

	.podria-interesar-container {
		height: 60rem;
	}

	.ver-mas-rutas {
		font-size: 3.4rem;
	}
	.ver-mas-rutas a {
		font-size: 3.4rem;
	}

	.popover-categoria_titulo{
		font-size: 3rem!important;
	}

	.popover-categoria_descripcion{
		font-size: 2rem!important;
	}
}

.contenedorBarraSeleccionada{
	position: relative;
}
.barraSeleccionada{
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
}

.carousel .carousel-slider{
	display: "flex" !important;
}

.tituloPrincipal {
	font-size: 2.5rem;
	color: black;
	text-align: center;
	margin-left: 2rem;
	font-weight: bold;
}

.tituloPrincipalResponsive {
	font-size: 1.8rem;
	color: black;
	text-align: center;
	margin-left: 2rem;
	font-weight: bold;
}

.tituloPrincipalLg {
	font-size: 3.6rem;
	color: black;
	text-align: center;
	font-weight: bold;
}

.tituloPrincipalSm {
	font-size: 1.3rem;
	color: black;
	text-align: center;
	font-weight: bold;
}

.tituloPrincipal4k span {
	font-size: 4.5rem !important;
	color: black;
	text-align: center;
	font-weight: bold;
}