.btn-custom:focus{
    box-shadow: 0 0 0 0.2rem #ffffff00;
}

.card-header-accordion:hover{
    background-color: #ff9900 !important;
}

.accordion-listado-cursos {
    color: black;
    font-size: 1.4rem;
    width: 100%;
    text-align: left;
    text-decoration: none!important;
}

.accordion-listado-cursos:hover {
    color: black;
}

.contenedor-ranking {
    height: 30vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.contenedor-ranking::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.contenedor-ranking::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.contenedor-ranking::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-ranking::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

.contenedor-ranking::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
}

.contenedor-ranking::-webkit-scrollbar-track:hover,
.contenedor-ranking::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}


.card-cursos-rutas {
    position: relative;
    width: 100%;
    height: 220px;
    overflow: hidden;
    border-radius: 10px;
    gap: 10px
  }

  .card-image{
    border-radius: 10px;
  }

.card-rutas .info .tomar-ruta:hover {
  background: dodgerblue;
  color: white;
}
