.separador {
	border-top: 3px solid #ffc107 !important;
}

.contenedor-foro {
	margin: 0 auto !important;
	width: 100% !important;
	max-width: 100%;
}

.nav-publicaciones .nav-link.active,
.nav-publicaciones .show > .nav-link {
	color: #000;
	background-color: #ffc107 !important;
}

.nav-publicaciones .nav-item a {
	color: #000 !important;
}

.p-colorpicker-overlay-panel {
	z-index: 2000 !important;
}

.contenedor-publicaciones {
	padding-top: 1rem;
	height: 300px;
	/* width: 100%; */
	overflow-y: scroll;
	overflow-x: hidden;
}

.contenedor-publicaciones::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-publicaciones::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-publicaciones::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-publicaciones::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-publicaciones::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-publicaciones::-webkit-scrollbar-track:hover,
.contenedor-publicaciones::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.actividad-empleados {
	height: 40vh;
    width: 100%;
    overflow: hidden!important;
    overflow-y: scroll!important;
    scrollbar-width: thin; /* Para navegadores que no usan WebKit (por ejemplo, Firefox) */
    scrollbar-color: transparent transparent; /* Color transparente para la barra y el fondo */
}