.carta {
    width: 75px;
    height: 75px;
    transition: scale 0.2s ease-out;
    margin: 1px 1px 10px 1px;
  }
  .carta:hover{
    scale: 1.1;
  }
  .portada {
    width: 75px;
    height: 75px;
    background-color: #212529;
    border-radius: 1rem;
	  filter: drop-shadow(2px 2px 4px #999999);
  }
  
  .contenido {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff9900;
    border-radius: 1rem;

  }
  
  .ReactFlipCard {
    width: 100%;
    height: 100%;
  }
  
  @media(min-width: 768px) {
    .carta{
      width: 115px;
      height: 115px;
    }
    .portada{
      width: 115px;
      height: 115px;
      border-radius: 2rem;
    }
    .contenido{
      width: 115px;
      height: 115px;
      border-radius: 2rem;
    }
  }