.container-form-registro {
	height: 100vh;
	width: 100vw;
	/* overflow: hidden; */
	overflow-x: hidden;
	overflow-y: scroll;
	/* background-color: #00152A; */
}

.container-form-registro::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.container-form-registro::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.container-form-registro::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.container-form-registro::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.container-form-registro::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.container-form-registro::-webkit-scrollbar-track:hover,
.container-form-registro::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.form-registro-inicial {
	color: white;
	font-size: 1.25rem;
}

.card-form-registro {
	background-color: #00152a;
}

.form-text {
	color: white !important;
}

.img-bg-form-registro {
	position: relative;
	background-image: url("@assets/images/bg-formulario-registro.jpeg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	/* overflow-y: hidden; */
	height: 100%;
	width: 100%;
}

.img-bg-form-registro.sai {
	height: 100% !important;
	min-height: 925px;
}

.card-form-registro > .card-body {
	padding: 0;
}

@media (max-width: 992px) {
	.img-bg-form-registro,
	.img-bg-form-registro.sai {
		height: 250px !important;
		min-height: inherit;
		background-position: left;
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
		background-image: url("@assets/images/bg-formulario-registro-mobile.jpeg");
	}
}

@media (min-width: 2000px) {
	.card-form-registro {
		margin-left: 11em;
		margin-right: 11em;
	}
}

/* Error page */

.error-main-title {
	color: #fb3958;
	/* font-size: 2em; */
	/* font-weight: 700; */
	text-transform: uppercase;
	text-align: center;
	text-shadow: 2px 2px 5px #b1041f;
}

.bg-error-page {
	background: #fff1f1;
}

.titulo-dashboard {
	font-size: 1.5rem;
}

.separador-titulo {
	width: 250px !important;
	/* height: 10px !important; */
}

.registro-segundo-usuario {
	cursor: pointer;
}

.registro-segundo-usuario:hover {
	text-decoration: underline;
	color: #ffcc00;
}

.privacy-policy-content {
	height: 100%;
	padding: 20px;
	overflow: auto;
}

.title-aviso-privacidad {
	text-align: left;
}

.description-aviso-privacidad {
	text-align: justify;
	padding-left: 15px;
}

.modal-90h .modal-content {
	height: 90vh;
	width: 100%;
}

.signature-canvas {
	width: 100%;
	height: calc(400px - 1rem);
	/* padding: 1rem; */
}

.signature-pad {
	height: 100%;
	width: 100%;
}

.signing-pad {
	border: 1px solid #ced4da;
	border-radius: 2px;
	background-color: #495057;
}

.signed-pad {
	border: none!important;
	background-color: #495057;
}

.signature-canvas-flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-csf {
	height: calc(3.5rem + calc(var(--bs-border-width) * 2));
}