@font-face {
	font-family: "Bodoni";
	font-family: "Futura";
	font-family: "Futura Book Light";
	font-family: "Futura Book BT";
	src: local("Bodoni"), url(/src/assets/ttf/Bodoni\ Regular.otf) format("opentype");
	src: local("Futura"), url(/src/assets/ttf/Futura\ Medium\ Italic\ font.ttf) format("truetype");
	src: local("Futura Book Light"), url(/src/assets/ttf/Futura\ Book\ Italic\ font.ttf) format("truetype");
	src: local("Futura Book"), url(/src/assets/ttf/FuturaBookBT.ttf) format("truetype");
}

.navbar {
	flex-wrap: nowrap;
	padding: 0;
}

.background-container {
	background-image: url("/src/assets/images/certificado.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 305px;
	height: 225px;
	margin-top: 10px;
	box-shadow: 1px 0 3px 3px rgb(0 0 0 / 15%), 0 4px 8px rgb(0 0 0 / 30%);
}

.certificate-title {
	margin-top: 40px;
	font-family: "Bodoni";
	letter-spacing: 2px;
	color: #242c35;
	text-align: center;
	font-size: 0.9rem;
	margin-bottom: -2px;
}

.certificate-subtitle {
	font-size: 0.4rem;
	font-family: "Bodoni";
	letter-spacing: 1px;
	font-weight: bold;
	margin-bottom: 12px;
}

.student-title {
	font-family: "Futura Book BT";
	font-size: 0.6rem;
	color: #32475c;
	font-style: italic;
}

.course-title {
	font-family: "Futura Book BT";
	font-size: 0.4rem;
	font-weight: 600;
	letter-spacing: 1px;
}

.info-director-title {
	font-family: "Futura Book BT";
	font-size: 0.4rem;
	font-weight: 600;
	letter-spacing: 1px;
}

.certificate-img {
	width: 35px;
	object-fit: contain;
}

.logo-certificate-container {
	margin: auto;
	width: 124px;
}

@media only screen and (min-width: 2560px) {
	.navbar-expand-lg {
		justify-content: space-between;
	}

	.background-container {
		width: 1550px;
		height: 1100px;
	}

	.certificate-title {
		font-size: 6rem !important;
		margin-top: 200px;
	}

	.certificate-subtitle {
		font-size: 2rem;
		margin-bottom: 80px;
	}

	.student-title {
		font-size: 4.5rem !important;
		margin-bottom: 40px;
	}

	.course-title {
		font-size: 2.5rem;
	}

	.info-director-title {
		font-size: 2.2rem;
	}

	.logo-certificate-container {
		width: 600px;
	}

	.certificate-img {
		width: 250px;
	}
}

@media only screen and (min-width: 1440px) and (max-width: 2559px) {
	.background-container {
		width: 855px;
		height: 600px;
	}

	.certificate-title {
		margin-top: 100px;
		font-size: 3.2rem;
	}

	.certificate-subtitle {
		margin-bottom: 40px;
		font-size: 1rem;
	}

	.student-title {
		margin-bottom: 30px;
		font-size: 2.5rem;
	}

	.course-title {
		font-size: 1.5rem;
	}

	.info-director-title {
		font-size: 1.2rem;
	}

	.logo-certificate-container {
		width: 350px;
	}

	.certificate-img {
		width: 140px;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
	.background-container {
		width: 615px;
		height: 425px;
	}

	.certificate-title {
		margin-top: 80px;
		font-size: 2.5rem;
	}

	.certificate-subtitle {
		font-size: 0.9rem;
	}

	.student-title {
		margin-bottom: 30px;
		font-size: 1.8rem;
	}

	.course-title {
		font-size: 0.8rem;
	}

	.info-director-title {
		font-size: 0.8rem;
	}
}

.background-container {
	background-image: url("/src/assets/images/certificado.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 305px;
	height: 225px;
	margin-top: 10px;
	box-shadow: 1px 0 3px 3px rgb(0 0 0 / 15%), 0 4px 8px rgb(0 0 0 / 30%);
}

.certificate-title {
	margin-top: 40px;
	font-family: "Bodoni";
	letter-spacing: 2px;
	color: #242c35;
	text-align: center;
	font-size: 1.1rem;
	margin-bottom: -2px;
}

.certificate-subtitle {
	font-size: 0.6rem;
	font-family: "Bodoni";
	letter-spacing: 1px;
	font-weight: bold;
	margin-bottom: 12px;
}

.student-title {
	font-family: "Futura Book BT";
	font-size: 1rem;
	color: #32475c;
	font-style: italic;
}

.course-title {
	font-family: "Futura Book BT";
	font-size: 0.7rem;
	font-weight: 600;
	letter-spacing: 1px;
}

.info-director-title {
	font-family: "Futura Book BT";
	font-size: 0.6rem;
	font-weight: 600;
	letter-spacing: 1px;
}

span:nth-last-child(3):not(".pointer") {
	margin-bottom: 3px;
}

.certificate-img {
	width: 45px;
	object-fit: contain;
}

.logo-certificate-container {
	margin: auto;
	width: 124px;
}

span:nth-last-child(1):not(".pointer") {
	margin-bottom: 7px;
}

@media only screen and (min-width: 2560px) {
	.navbar-expand-lg {
		justify-content: space-between;
	}

	.background-container {
		width: 1550px;
		height: 1100px;
	}

	.certificate-title {
		font-size: 6rem !important;
		margin-top: 200px;
	}

	.certificate-subtitle {
		font-size: 2rem;
		margin-bottom: 80px;
	}

	.student-title {
		font-size: 4.5rem !important;
		margin-bottom: 40px;
	}

	.course-title {
		font-size: 2.5rem;
	}

	.info-director-title {
		font-size: 2.2rem;
	}

	.logo-certificate-container {
		width: 600px;
	}

	.certificate-img {
		width: 250px;
	}
}

@media only screen and (min-width: 1440px) and (max-width: 2559px) {
	.background-container {
		width: 855px;
		height: 600px;
	}

	.certificate-title {
		margin-top: 100px;
		font-size: 3.2rem;
	}

	.certificate-subtitle {
		margin-bottom: 40px;
		font-size: 1rem;
	}

	.student-title {
		margin-bottom: 30px;
		font-size: 2.5rem;
	}

	.course-title {
		font-size: 1.5rem;
	}

	.info-director-title {
		font-size: 1.2rem;
	}

	.logo-certificate-container {
		width: 350px;
	}

	.certificate-img {
		width: 140px;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
	.background-container {
		width: 615px;
		height: 425px;
	}

	.certificate-title {
		margin-top: 80px;
		font-size: 2.5rem;
	}

	.certificate-subtitle {
		font-size: 0.9rem;
	}

	.student-title {
		margin-bottom: 30px;
		font-size: 1.8rem;
	}

	.course-title {
		font-size: 1rem;
	}

	.info-director-title {
		font-size: 1rem;
	}

	.logo-certificate-container {
		width: 240px;
	}

	.certificate-img {
		width: 85px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.background-container {
		width: 485px;
		height: 337px;
		background-size: cover;
	}
	.certificate-title {
		margin-top: 70px;
		font-size: 1.5rem;
	}

	.certificate-img {
		width: 60px;
	}
	.logo-certificate-container {
		width: 200px;
	}
	.student-title {
		font-size: 1.2rem;
	}
}
