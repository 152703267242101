html[data-bs-theme="dark"] > body > #root > .parent-container > div.animated > nav > span > div:nth-child(2) > div.contenedor-busqueda {
	background-color: rgb(33 37 41)!important;
	color: #FFF;
}

.contenedor-busqueda {
	height: 40vh;
	background: #f1f1f1;
	overflow-y: auto;
	overflow-x: hidden;
}

.contenedor-busqueda::-webkit-scrollbar {
	width: 8px; 
	height: 8px; 
}

.contenedor-busqueda::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-busqueda::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-busqueda::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-busqueda::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-busqueda::-webkit-scrollbar-track:hover,
.contenedor-busqueda::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.fila-resultados{
	padding: 1rem;
}

.fila-resultados:hover{
	background-color: #ff9900;
}