
.plus-icon{
    height: auto;
}

.imagen-pregunta{
    width: 180px;
}

.pregunta{
    font-size: 14px;
}

.titulo-accordion{
    font-size: 15px;
    color: grey;
}
.contenido-pregunta p{
    font-size: 14px;
    text-align: start;
}

.texto-izquierda{
    text-align: start;
}