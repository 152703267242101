header {
    height: 50px;
    border: none;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;;
    align-content: center;
  }
  
  .titulo {
    font-size: 25px;
    padding: 10px;
    color: black;
  }

  .titulo span{
    font-size: 2rem;
    color: #ff9900;
  }
  
  .boton-reiniciar {
    position: absolute;
    left: 12px;
    top: 58px;
  }
