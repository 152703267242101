.align {
	margin-left: auto;
	margin-right: auto;
}

.animate-left {
	transition: 0.5s ease-in-out;
	transform: translate(0%, 0%);
}

.animate-right {
	transition: 0.5s ease-in-out;
	transform: translate(100%, 0%);
}

.avatar-seleccion-perfil {
	border-radius: 4px;
	padding: 5px;
	width: 150px;

	background-repeat: no-repeat;
	background-size: 100%;
}

.avatar-inicio {
	border-radius: 50% !important;
	margin: 1rem auto 1rem auto;
	height: 8rem;
	width: 8rem;
	background-image: url("@assets/images/usuario.svg");
	background-repeat: no-repeat;
	background-size: 100%;
}

.img-course-icons {
	width: 25rem;
	height: 14rem;
	margin-left: auto;
	margin-right: auto;
	background-image: url("@assets/images/course-icons.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
}

.imgKey {
	height: 6rem;
	width: 6rem;
	background-image: url("@assets/images/email.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
}

.img-logo {
	width: 15rem;
	height: 6rem;
	margin-left: auto;
	margin-right: auto;
}

.fade-in {
	opacity: 1;
	animation: fade 0.3s linear;
}

.form {
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	padding: 25%;
	text-align: center;
}

.forms {
	position: absolute;
	z-index: 10;
}

.formRegistro {
	background-image: url("@assets/images/back_registro.jpg");
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	margin: 0%;
	width: 100%;
	height: 100%;
}

.panel {
	align-items: center;
	text-align: center;
	justify-content: center;
	display: flex;
	height: 100vh;
	margin: 0 auto 0 auto;
	padding: 3rem;
	position: absolute;
	text-align: center;
	width: 50vw;
	z-index: 7;
	overflow: hidden;
}

.paneles {
	position: absolute;
	z-index: 12;
}

.blue-vector {
	background-image: url("@assets/images/back2.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 0;
	position: absolute;
	width: 50vw;
	height: 100vh;
	z-index: 11;
	overflow: hidden;
}

.mobile-blue-vector {
	align-items: center;
	background-image: url("@assets/images/mobile-login-cover.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	position: absolute;
	text-align: center;
	width: 100vw;
	z-index: 20;
}

.blue-vector::before {
	content: "";
	position: absolute;
	background-color: #00253c;
	height: 100%;
	width: 100%;
	opacity: 0.9;
}

@keyframes fade {
	0%,
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
