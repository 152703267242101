@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
.contenedor-misiones {
	overflow-y: auto;
	overflow-x: hidden;
}

.contenedor-misiones::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-misiones:not(.cambio-licencia) {
	height: 50vh;
}

.contenedor-misiones::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-misiones::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-misiones::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-misiones::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-misiones::-webkit-scrollbar-track:hover,
.contenedor-misiones::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.separador-misiones{
	border: 1px solid rgb(13, 13, 13);
}


.contenedor-participaciones {
	overflow-y: auto;
	overflow-x: hidden;
}

.contenedor-participaciones:not(.cambio-licencia) {
	height: 50vh;
}

.contenedor-participaciones::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-participaciones::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-participaciones::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-participaciones::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-participaciones::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-participaciones::-webkit-scrollbar-track:hover,
.contenedor-participaciones::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}


.sin-participantes{
	font-size: 2.3rem;
	color: gray;
}

.card-proyecto{
	background-color: #ededed;
}

.titulo-ganadores{
	font-family: 'Lexend Deca', sans-serif;
}

.resaltado {
	font-size: 40px;
	color: #ff9900;
}

@media(min-width: 2500px){
	.resaltado{
		font-size: 5rem !important;
	}
}

.resaltado-terminos {
	font-size: 20px;
	color: #ff9900;
}

.contenedor-ganadores{
	background-image: url("../../../images/fondoGanadores.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 100vh; 
}

.creditos{
	font-size: 20px;
	color: #ff9900;
}




.contenedor-recompensas {
	overflow-y: auto;
	overflow-x: hidden;
}

.contenedor-recompensas::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-recompensas:not(.cambio-licencia) {
	height: 60vh;
}

.contenedor-recompensas::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-recompensas::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-recompensas::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-recompensas::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-recompensas::-webkit-scrollbar-track:hover,
.contenedor-recompensas::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.separacion-misiones{
	padding-bottom: 8rem;
}


@media(min-width: 2000px) {
	.separacion-misiones{
		padding-bottom: 20rem;
	}
}

@media(max-width: 992px) {
	.separacion-misiones{
		padding-bottom: 10rem;
	}
}

@media(max-width: 768px) {
	.separacion-misiones{
		padding-bottom: 15rem;
	}
}

.img-mision{
	width: fit-content;
	
}


@media(min-width: 2000px){

	.nombre-ganador{
		font-size: 2rem !important;
	}

	.avatar-misiones{
		width: 350px;
		height: 350px;
	}
}


.descripcion-recompensas{
	font-size: 14px;
}

/* @media(min-width: 2000px){
	.descripcion-recompensas{
		font-size: 18px;
	}
} */