@media (min-width: 2000px) {
	.container {
		max-width: 100%;
	}
}

.contenedor-cursos {
	background: var(--contenedor-cursos-background);
	overflow-y: auto;
	overflow-x: hidden;
}

.contenedor-cursos:not(.cambio-licencia) {
	height: 50vh;
}

.contenedor-cursos::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-cursos::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-cursos::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-cursos::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-cursos::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-cursos::-webkit-scrollbar-track:hover,
.contenedor-cursos::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #ffffff;

	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 60%;
	height: 100%;
	object-fit: cover;
}
