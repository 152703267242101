.lista-datos-membresia > li > span{
    font-weight: bold;
}

.card-option-nip {
    background-color: #f3f3f3;
    cursor: pointer;
    text-align: center;
}

.card-option-nip:hover {
    background-color: #ffcc00;
}