.tablero {
    display: flex;
    flex-wrap:wrap;
    width: 350px !important;
    height: 250px !important;
    margin: 0 auto;
    justify-content: space-around;
  }

  @media(min-width: 768px){
    .tablero{
      width: 700px !important;
      height: 600px !important;
      margin-bottom: 10rem;
    }
  }
  

  @media(max-width: 1250px){
    .tablero{
      width: 600px;
      height: 500px;
    }
  }