/* .cursos-agregados-licencia {
    background-color: #f1f1f1;
} */

.cursos-agregados-licencia,
.lista-cursos-disponibles {
	border-radius: 5px;
	height: 50vh;
    padding-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.cursos-agregados-licencia,
.lista-cursos-disponibles {
	overflow-y: auto;
	overflow-x: hidden;
}

.cursos-agregados-licencia::-webkit-scrollbar,
.lista-cursos-disponibles::-webkit-scrollbar {
	width: 8px; 
	height: 8px; 
}

.cursos-agregados-licencia::-webkit-scrollbar-thumb,
.lista-cursos-disponibles::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.cursos-agregados-licencia::-webkit-scrollbar-thumb:hover,
.lista-cursos-disponibles::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.cursos-agregados-licencia::-webkit-scrollbar-thumb:active,
.lista-cursos-disponibles::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.cursos-agregados-licencia::-webkit-scrollbar-track,
.lista-cursos-disponibles::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.cursos-agregados-licencia::-webkit-scrollbar-track:hover,
.cursos-agregados-licencia::-webkit-scrollbar-track:active,
.lista-cursos-disponibles::-webkit-scrollbar-track:hover,
.lista-cursos-disponibles::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}
