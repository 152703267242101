:root {
	--transparente: transparent;
}

.btn-input {
	color: var(--transparente) !important;
	background-color: var(--transparente) !important;
}

.editar-nivel-seccion-item {
	border: 1px solid #e3e3e3;
	border-radius: 5px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.editar-nivel-seccion-item:hover {
	background-color: #e3e3e3;
}