.contenedor-juegos {
	background: var(--contenedor-juegos-background);
	overflow-y: auto;
	overflow-x: hidden;
}

.contenedor-juegos:not(.cambio-licencia) {
	height: 100vh;
}

.contenedor-juegos::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-juegos::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-juegos::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-juegos::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-juegos::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-juegos::-webkit-scrollbar-track:hover,
.contenedor-juegos::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.inserte-moneda, .card-juego{
	filter: drop-shadow(4px 4px 8px #999999);
	transition: scale 0.2s ease-out;
	
}

.inserte-moneda:hover{
	scale: 0.9;
}

.card-juego:hover{
scale: 0.9;
}