@charset "UTF-8";
.main-content-perfiles {
  background-image: url("@assets/images/back_perfiles.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.avatar-perfiles {
  margin: 0 auto;
  width: 80%;
  height: 100%;
  padding: 1rem;
  border-radius: 3rem;
  background-color: rgba(0, 0, 0, 0.738);
  transition: transform 0.2s ease-in;
}

.avatar-perfiles:hover  {
  -ms-transform: scale(1.1); 
  -webkit-transform: scale(1.1); 
  transform: scale(1.1);
  cursor: pointer;
} 

.avatar-perfiles:hover .avatar-seleccion-perfiles {
  background-color: #ffcc00;
}


.avatar-seleccion-perfiles {
  border-radius: 100%;
  background-color: aliceblue;
  padding: 4px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.avatar-seleccion-perfiles__vacio {
  border-radius: 100%;
  background-color: aliceblue;
  padding: 4px;
  background-repeat: no-repeat;
  background-size: 100%;
}




/* @media(min-width:1900px) {
  .avatar-seleccion-perfiles, .avatar-seleccion-perfiles__vacio{
    width: 180px;
  }
} */

.nombre-perfiles{
  font-size: 1rem;
    color: aliceblue;
    display: block;
}

@media(min-width:1900px){
  .nombre-perfiles{
    font-size: 2rem;
  }
}

/* .main-content {
  overflow-y: hidden;
  overflow-x: hidden;
}

.main-content::-webkit-scrollbar {
  width: 8px; 
  height: 8px; 
}

.main-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.main-content::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.main-content::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.main-content::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.main-content::-webkit-scrollbar-track:hover,
.main-content::-webkit-scrollbar-track:active {
  background: #d4d4d4;
} */

.forgotten-nip {
  font-size: medium;
  text-align: right;
}