.lista-lecciones {
	background-color: rgb(238, 238, 238);
}

.ruta {
	margin-top: 1rem;
	transition: transform 0.2s ease-in;
}
.ruta .card-rutas {
	border-radius: 3rem;
	height: 13rem;
	min-height: 13rem;
}
.ruta:hover {
	-ms-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	cursor: pointer;
}

.card-header-rutas-aprendizaje {
	padding: 25px 0;
	text-align: center;
}

.panel-lecciones {
	background-color: rgb(229, 229, 229);
	height: 100%;
}

@media only screen and (max-width: 768px) {
	.card-rutas-agregar-ruta {
		width: 15rem !important;
		padding: 20px !important;
	}
}

.btn-fullscreen {
	font-size: 1rem;
}

@media (min-width: 2500px) {
	.col-rutas {
		height: 500px !important;
	}
	.btn-fullscreen {
		font-size: 3rem;
	}

	.titulo-video {
		font-size: 2.5rem;
	}

	.titulo-ruta-predefinida h3 {
		font-size: 3.5rem;
	}

	.titulo-accordion {
		font-size: 2rem;
	}

	.sin-lecciones {
		font-size: 2.5rem;
	}
}

@import url("https://fonts.googleapis.com/css?family=Lato");
.outer {
	min-width: 20vw;
	flex: 1;
	height: 600px;
}

.progress {
	display: inline-flex;
	height: 70%;
	padding-left: 1rem;
	background: none;
}

.progress > div {
	display: flex;
	flex-direction: column;
	color: #333;
}
.progress > div.left {
	padding-right: 20px;
	text-align: right;
}
.progress > div.left div:last-of-type:after {
	display: none;
}
.progress > div.left div:after {
	content: "";
	position: absolute;
	right: -20px;
	top: 10px;
	height: 101%;
	width: 1px;
	transform: translateX(50%);
}
.progress > div.right {
	padding-left: 20px;
}
.progress > div.right div.prev:after {
	transition: none;
}
.progress > div.right div.current {
	color: #333;
	font-weight: bold;
}
.progress > div.right div.current:before {
	background: #ff9900;
	padding: 10px;
	transition: all 0.2s 0.15s cubic-bezier(0.175, 0.885, 0.32, 2);
}

.progress > div.right div.current:after {
	height: 0%;
	transition: height 0.2s ease-out;
}
.progress > div.right div.current ~ div {
	color: #666;
}
.progress > div.right div.current ~ div:before {
	background: #666;
	padding: 2.5px;
}

.progress > div.right div.current ~ div:after {
	height: 0%;
	transition: none;
}
.progress > div.right div:before {
	content: "";
	background: #ff9900;
	padding: 5px;
	border-radius: 50%;
	position: absolute;
	left: -20px;
	top: 10px;
	transform: translateX(-50%) translateY(-50%);
	transition: padding 0.2s ease;
}

.progress > div.right div:after {
	content: "";
	background: #ff9900;
	border-radius: 2px;
	position: absolute;
	left: -20px;
	top: 10px;
	height: 105%;
	width: 2px;
	transform: translateX(-50%);
	transition: height 0.2s ease;
}

@media (min-width: 1500px) {
	.progress > div.right div:after {
		height: 100%;
	}
}

.progress > div div {
	flex: 1;
	position: relative;
	line-height: 13px;
	cursor: default;
	min-height: 30px;
	margin-bottom: 10px;
}

@media (min-width: 1920px) {
	.progress {
		height: 100%;
	}
}

@media (min-width: 2000px) {
	.outer {
		height: 70vh;
	}
	.outer h5 {
		font-size: 4rem;
	}
	.progress {
		padding-left: 2rem;
	}
	.progress > div.right {
		padding-left: 20px;
		padding-top: 2rem;
	}
	.progress > div.right div.current:before {
		padding: 15px;
	}
	.leccion-lista {
		font-size: 2.3rem;
		padding-left: 3rem;
	}
	.progress > div.right div.current ~ div:before {
		padding: 6.5px;
	}
	.progress > div.right div:before {
		padding: 10px;
	}
	.progress > div.right div:after {
		height: 100%;
	}
	.progress > div.right div:after {
		height: 185%;
	}
	.progress > div div {
		margin-bottom: 30px;
	}
	/* .ver-mas-rutas{
    font-size: 2rem;
  } */
}

.progress > div div:last-of-type {
	flex: 0;
}

.progress.dark {
	background: #ff9900;
	display: inline-flex;
	height: 100%;
	width: 100%;
	padding: 5vh 10%;
}
.progress.dark > div {
	display: flex;
	flex-direction: column;
	color: #ccc;
}
.progress.dark > div.left {
	padding-right: 20px;
	text-align: right;
}
.progress.dark > div.left div:last-of-type:after {
	display: none;
}
.progress.dark > div.left div:after {
	content: "";
	background: rgba(211, 211, 211, 0.05);
	border-radius: 2px;
	position: absolute;
	right: -20px;
	top: 10px;
	height: 101%;
	width: 1px;
	transform: translateX(50%);
}
.progress.dark > div.right {
	padding-left: 20px;
}
.progress.dark > div.right div.prev:after {
	transition: none;
}
.progress.dark > div.right div.current {
	color: white;
	font-weight: bold;
}
.progress.dark > div.right div.current:before {
	background: white;
	padding: 10px;
	transition: all 0.2s 0.15s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.progress.dark > div.right div.current:after {
	height: 0%;
	transition: height 0.2s ease-out;
}
.progress.dark > div.right div.current ~ div {
	color: #666;
}
.progress.dark > div.right div.current ~ div:before {
	background: #666;
	padding: 2.5px;
}
.progress.dark > div.right div.current ~ div:after {
	height: 0%;
	transition: none;
}
.progress.dark > div.right div:before {
	content: "";
	background: lightgray;
	padding: 5px;
	border-radius: 50%;
	position: absolute;
	left: -20px;
	top: 10px;
	transform: translateX(-50%) translateY(-50%);
	transition: padding 0.2s ease;
}
.progress.dark > div.right div:after {
	content: "";
	background: lightgray;
	border-radius: 2px;
	position: absolute;
	left: -20px;
	top: 10px;
	height: 101%;
	width: 2px;
	transform: translateX(-50%);
	transition: height 0.2s ease;
}

.progress.dark > div div {
	flex: 1;
	position: relative;
	line-height: 20px;
	cursor: default;
	min-height: 30px;
}
.progress.dark > div div:last-of-type {
	flex: 0;
}

.done.current {
	color: #62af0b !important;
}
.done.current:before {
	background: #62af0b !important;
}

.dark .done.current {
	color: lightgreen !important;
}
.dark .done.current:before {
	background: lightgreen !important;
}

.finalizar-ruta {
	background-color: #62af0b;
	border: none;
}

.finalizar-ruta:hover {
	background-color: #84db21;
}

@media (min-width: 2500px) {
	.finalizar-ruta {
		font-size: 2.8rem;
	}
}

.wrapper {
	display: flex;
	width: 100%;
	justify-content: space-around;
}

.card-rutas {
	margin-top: 1.5rem;
	width: 100%;
	height: 200px;
	min-height: 150px;
	border-radius: 15px;
	padding: 0;
	background: white;
	position: relative;
	display: flex;
	align-items: center;
	transition: 0.4s ease-out;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 450px) {
	.card-rutas {
		width: 100%;
	}
}

@media (min-width: 550px) {
	.card-rutas {
		width: 60%;
	}
}

@media (min-width: 630px) and (max-width: 768px) {
	.card-rutas {
		width: 50%;
	}
}

@media (min-width: 2500px) {
	.card-rutas {
		height: 375px;
	}
}

.card-rutas:hover {
	transform: translateY(10px);
}
.card-rutas:hover:before {
	opacity: 1;
}
.card-rutas:hover .info {
	opacity: 1;
	transform: translateY(0px);
}
.card-rutas:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 15px;
	background: rgba(0, 0, 0, 0.6);
	z-index: 2;
	transition: 0.5s;
	opacity: 0;
}
.card-rutas img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 15px;
}
.card-rutas .info {
	padding: 0.5rem;
	position: relative;
	z-index: 3;
	color: white;
	opacity: 0;
	transform: translateY(30px);
	transition: 0.5s;
	width: 100%;
}

.card-body-titulo {
	margin: 0px;
	padding-top: 1rem;
}
.card-body-descripcion {
	letter-spacing: 1px;
	font-size: 10px;
}
.card-rutas .info .tomar-ruta {
	padding: 0.4rem;
	outline: none;
	border: none;
	border-radius: 5px;
	background: white;
	color: black;
	font-weight: bold;
	transition: 0.4s ease;
	font-size: 12px;
}

@media (min-width: 2500px) {
	.card-body-descripcion {
		letter-spacing: 1px;
		font-size: 30px;
	}

	.card-rutas .info .tomar-ruta {
		font-size: 28px;
	}

	.alert-rutas {
		font-size: 2rem !important;
	}
}

/*
    ! NOTA IMPORTANTE:
    ! NO APLICAR CSS A CLASES DE BOOTSTRAP, AFECTA A ESTILOS DE TODA A PLATAFORMA
    ! UTILIZAR CLASES PERSONALIZADAS O ID'S
  */
/* .btn:not(:disabled):not(.disabled){
    cursor: default !important;
  }

    .info .dropdown-toggle {
    background: none;
    border: none;
    }

    .info .btn:focus{
      box-shadow: none;
    }

    .info .btn-secondary:focus{
      box-shadow: none;
    }

    .info .show > .btn-secondary.dropdown-toggle {
      background: none;
    }

    .info .show>.btn-secondary.dropdown-toggle:focus {
      box-shadow: none;
      border: none;
    } */

/*CARD DE LAS CATEGORIAS*/

.card-predefinidas {
	margin: 0 auto;
	width: 100%;
	height: 100%;
	transition: transform 0.2s ease-in;
}

.card-predefinidas img {
	border-radius: 10px 10px 10px 10px;
}

.card-predefinidas:hover {
	-ms-transform: scale(1.05); /* IE 9 */
	-webkit-transform: scale(1.05); /* Safari 3-8 */
	transform: scale(1.05);
	cursor: pointer;
}

.card-predefinidas.invitado {
	height: auto;
}

.card-predefinidas.invitado:hover {
	cursor: unset;
}

.titulo-ruta {
	margin: 10px 0px 0px 0px !important;
}

.card-ruta {
	margin: 0 auto;
	width: 100%;
	height: 100%;
	transition: transform 0.2s ease-in;
}

.card-ruta:hover {
	-ms-transform: scale(1.1); /* IE 9 */
	-webkit-transform: scale(1.1); /* Safari 3-8 */
	transform: scale(1.1);
	cursor: pointer;
}

.card-ruta img {
	border-radius: 10px 10px 0px 0px;
}

.menu-ruta .dropdown-toggle {
	font-size: 0.8rem;
	margin-right: 1rem;
}

.menu-ruta .dropdown-toggle {
	background-color: none;
}
.menu-ruta .dropdown-toggle::before {
	display: none;
}

.rutas-aprendizaje .contenedor-rutas {
	padding-top: 1rem;
	height: 40vh;
	width: 100%;
	background: #f1f1f1;

	overflow-y: scroll;
	overflow-x: hidden;
}

.contenedor-rutas::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-rutas::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-rutas::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-rutas::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-rutas::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-rutas::-webkit-scrollbar-track:hover,
.contenedor-rutas::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}

.scrollTop:hover {
	background-color: #d97706 !important;
}
