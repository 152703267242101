@media (min-width: 2560px ){
    .avatar-inicio{
        height: 14rem;
        width: 14rem;
    }

    /* h2{
        font-size: 3rem!important;
    }

    h3{
        font-size: 3.5rem!important;
    } */

    .input-4{
        font-size: 2rem;
        /* margin: 1rem 0 1rem 0; */
    }

    .feedback-4{
        font-size: 1.5rem;
    }

    .btn4{
        border-radius: 10px;
        font-size: 2rem;
        margin: 1rem 0 1rem 0;
        padding: 1rem;
    }

    .copyright{
        font-size: 2rem;
    }

    .p{
        font-size: 2rem;
    }
}