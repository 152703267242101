:root {
    --border-radius: 8px;
	--border-radius-transmition: 0 0 var(--border-radius) var(--border-radius);
}
.breadcrumb-item svg {
	color: #ff9900;
}

/* .breadcrumb {
	background-color: #fff !important;
} */
.page-item.active .page-link{
	background-color: #ff9900;
	border-color: #ff9900;
}
.page-link{
	color: #ff9900;
}
.page-link:hover {
	color: #ff99007e;
}
.card-title-aula-virtual{
	font-size: 1rem;
}