.contenedor-estadisticas1{
    background-color: rgba(222, 222, 222, 0.8);
    padding: 20px;
    border-radius: 5px;
}

.contenedor-estadisticas2{
    padding: 20px;
}

.contenedor-graficos {
	overflow-y: auto;
	overflow-x: hidden;
}

.contenedor-graficos:not(.cambio-licencia) {
	height: 85vh;
}

.contenedor-graficos::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-graficos::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-graficos::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-graficos::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-graficos::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-graficos::-webkit-scrollbar-track:hover,
.contenedor-graficos::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}