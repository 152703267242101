.bg-main-container {
    background: -moz-radial-gradient(circle, rgba(255, 255, 255, 1) 0, rgba(153, 153, 153, 1) 100%);
    background: -webkit-radial-gradient(circle, rgba(255, 255, 255, 1) 0, rgba(153, 153, 153, 1) 100%);
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0, rgba(153, 153, 153, 1) 100%);

    height: 100vh;
}

.bg-light-dark {
	background-color: rgba(52, 58, 64, .9);
}

.py-30 {
	padding-bottom: 30px;
	padding-top: 30px;
}

.logo-footer {
    height: 50%;
    width: 50%;
}

.bg-footer {
    background-color: #FFCC00;
}