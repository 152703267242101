:root{
    --admin-panel-item-width: 200px;
    --admin-panel-item-height: var(--admin-panel-item-width);
    --admin-panel-item-icon-width: calc(var(--admin-panel-item-width) - 50px);
}

/* .admin-panel-item {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    width: var(--admin-panel-item-width);
    height: var(--admin-panel-item-height);
    width: 200px;
    height: 200px;
    text-align: center;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
} */

.admin-panel-item-icon {
    width: var(--admin-panel-item-icon-width);
}

.admin-panel-item-title {
    margin: 0;
    position: absolute;
    bottom: 0.1px;
    left: 0;
    right: 0;
}

.back-to-admin-panel {
	color: #6c757d;
    font-size: 1.5rem;
}

.back-to-admin-panel:hover {
	color: #343a40;
	/* scale: 1.2; */
}


@media(min-width: 2000px){
    .admin-panel-item-icon {
        width: 300px;
    }
}


@media(min-width: 4000px){
    .admin-panel-item-icon {
        width: 400px;
    }
}