.respuesta-chat {
	padding: 1.5rem;
	color: white;
}

.respuesta-chat.ia {
    background-color: #444654;
}

.respuesta-chat.user {
    background-color: #343541
}

.container-historial-messages {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin; /* Para navegadores que no usan WebKit (por ejemplo, Firefox) */
    scrollbar-color: transparent transparent; /* Color transparente para la barra y el fondo */
}

/* Solo para navegadores WebKit (por ejemplo, Chrome y Safari) */
.container-historial-messages::-webkit-scrollbar {
    width: 8px;
}

.container-historial-messages::-webkit-scrollbar-thumb {
    background: transparent; /* Hacer la barra de desplazamiento invisible */
}

.container-historial-messages::-webkit-scrollbar-track {
    background: transparent; /* Hacer el fondo de la barra de desplazamiento invisible */
}

.input-chat-ia {
    resize: none;
    color: '#000';
    background-color: '#fff';
    appearance: none;
    border-color: #8e8ea0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
} 

.input-chat-ia:focus {
    border-color: #8e8ea0;
    appearance: none;
    box-shadow: none;
    outline: none;
}

.code-block-header {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    background-color: #343541;
    color: white;
}

.overflow-y-auto{
    overflow-y: auto;
}

.copy-code-button, 
.copy-code-button:active,
.copy-code-button:focus,
.copy-code-button:hover {
    background-color: transparent!important;
    background-image: none!important;
    border-color: transparent!important;
    box-shadow: none!important;
}

.bg-black {
    background-color: #000;
}