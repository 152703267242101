.parent-container {
	height: 100vh;
	overflow: hidden;
}

.custom-sidebar {
	background-color: #00152a;
}

.custom-sidebar.active {
	width: 10vw;
}

.custom-sidebar::-webkit-scrollbar {
	display: none;
}

.avatar {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	background-color: #fff;
}

.search-bar {
	position: relative;
	direction: ltr;
	display: block;
	flex: 1 1;
}

.bg-dark-custom {
	background-color: var(--bg-dark-custom);
}

.dropdown-cursos-categorias-list-item {
	border: 1px solid #dae0e5 !important;
}

.custom-sidebar-item .nav-link {
	color: #fff;
}

.custom-sidebar-item:hover {
	background-color: #ff9900;
}

.custom-sidebar-item:hover .nav-link {
	color: #000;
}

.custom-sidebar-item.active {
	background-color: #ff9900;
}

.custom-sidebar-item.active .nav-link {
	color: #000;
}

.navbar-gc {
	height: 10vh;
}

.main-content {
    height: 90vh;
    width: 100%;
    overflow: hidden!important;
    overflow-y: scroll!important;
    scrollbar-width: thin; /* Para navegadores que no usan WebKit (por ejemplo, Firefox) */
    scrollbar-color: transparent transparent; /* Color transparente para la barra y el fondo */
}

.main-content__concentracion{
	height: 100vh;
}

/* Solo para navegadores WebKit (por ejemplo, Chrome y Safari) */
.main-content::-webkit-scrollbar {
    width: 0px;
}

.main-content::-webkit-scrollbar-thumb {
    background: transparent; /* Hacer la barra de desplazamiento invisible */
}

.main-content::-webkit-scrollbar-track {
    background: transparent; /* Hacer el fondo de la barra de desplazamiento invisible */
}

@media (min-width: 768px) {
	#mobile-navbar {
		display: flex !important;
		height: 100% !important;
		flex-direction: column;
		max-height: calc(100vh - 8.5rem);
		min-height: calc(100vh - 8.5rem);
	}
}

@media (max-width: 575px) {
	.custom-sidebar {
		display: none;
	}

	.custom-sidebar.active {
		display: inline-block;
		width: 100vw;
		z-index: 99;
	}

	.main-content {
		width: 100vw;
		margin: 0;
		padding: 0;
	}
}

@media (min-width: 576px) and (max-width: 991px) {
	.custom-sidebar {
		display: none;
	}

	.buscar {
		width: 45vw;
	}

	.custom-sidebar.active {
		display: inline-block;
		width: 25vw;
		z-index: 99;
	}

	.main-content {
		margin: 0 auto 0 auto;
		padding: 0.5rem;
		width: 100vw;
	}

	.main-content.active {
		margin: 0 auto 0 auto;
		padding: 0.5rem;
		width: 100vw;
		position: fixed;
	}

	.dropdown-username {
		max-width: 18vw;
		overflow: hidden;
		text-overflow: ellipse;
		white-space: nowrap;
	}
}

@media (min-width: 2560px) {
	.btn-menu-dashboard {
		font-size: 2.5rem;
	}

	.btn-menu-dashboard {
		max-width: 5rem;
		max-height: 5rem;
	}

	.buscar {
		width: 70vw;
	}

	.logo-brand {
		height: 3rem;
		width: 8rem;
	}

	.menuInactive {
		font-size: 2rem;
	}

	.menuActive {
		font-size: 1.5rem;
	}

	.buscar {
		font-size: 1.5rem;
	}

	.avatar-usr {
		height: 3rem;
		width: 3rem;
	}

	.dropdown-username {
		font-size: 1.5rem;
	}

	.dropdown-menu.show {
		font-size: 1.5rem;
	}
}

.sidebar-scroll > div::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.sidebar-scroll > div::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.sidebar-scroll > div::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.sidebar-scroll > div::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.sidebar-scroll > div::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.sidebar-scroll > div::-webkit-scrollbar-track:hover,
.sidebar-scroll > div::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}


.btn-notificaciones,
.btn-notificaciones:hover,
.btn-notificaciones:active {
	background: none;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: var(--btn-notificaciones-color);
}

.btn-notificaciones:focus,
.btn-notificaciones:focus-visible {
	outline: none;
    background: none;
    box-shadow: none;
}

.p-badge.icon-no-leidas {
	height: 1rem!important;
	min-width: 1rem!important;
}

/* .notificacion-usuario:hover {
	background-color: #f3f3f3;
} */

.radius-notificacion {
	border-radius: 5px;
}

/* .notificacion-usuario.leida {
	color: #56585a;
}

.notificacion-usuario.no-leida {
	color: black;
} */

#doorbell-feedback {
	margin-top: 15px!important;
}

#doorbell-form,
#doorbell-feedback,
#doorbell-email {
	background-color: var(--body-background)!important;
}

#doorbell-title {
	color: var(--body-color)!important;
}

/* .apexcharts-datalabel-value */