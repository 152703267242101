:root {
	--transparente: transparent;
}

.btn-input {
	color: var(--transparente) !important;
	background-color: var(--transparente) !important;
}

.seccion-accordion {
    border: 1px solid rgba(0, 0, 0, .125);
}

.hovered-accordion-toggle:hover {
	
}

.editar-niveles-secciones {
	color: #6c757d;
	cursor: pointer;
}

.editar-niveles-secciones:hover {
	color: #007bff;
}