.fondo-foro {
	background-image: url("@assets/images/foroAyuda.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: white;
	margin-bottom: 1rem;
	border-radius: 10px;
}

.card-post-foro-ayuda {
    width: 100%;
    /* background-color: whitesmoke; */
    border-radius: 20px;
    margin: auto; 
    padding: .5rem;
    height: 300px;
}

.fondo-foro-titulo {
	height: 200px;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.505);
}

.btn-like {
	background-color: #991c1c9f;
	color: #fff;
}

.btn-like:hover {
	background-color: #6212129f;
	color: #fff;
}

.nav-categorias .nav-link.active,
.nav-pills .show > .nav-link {
	color: #000;
	background-color: #ffc107 !important;
}

.slide-reportar {
	width: 100% !important;
}

.btn-atendidos.p-button.p-highlight {
	background-color: #ffc107 !important;
	border-color: #ffc107 !important;
	color: #6c757d !important;
}

/* //* ESTILOS PARA LOS BOTONES DE MOSTRAR Y OCULTAR LOS REPORTES ATENDIDOS*/

.btn-atendidos.p-button.p-highlight {
	background-color: #ffc107 !important;
	border-color: #ffc107 !important;
	color: #6c757d !important;
}

.btn-atendidos.p-button .p-button-icon-left,
.btn-atendidos.p-button .p-button-icon-right {
	color: #6c757d !important;
}

@media (min-width: 2000px) {
	.fondo-foro-titulo {
		height: 400px;
	}

	.titulo-foro {
		font-size: 4rem !important;
	}
	.svg-foro {
		font-size: 2rem !important;
	}
	.separador-foro {
		width: 300px !important;
	}
}

@media (min-width: 4000px) {
	.fondo-foro-titulo {
		height: 700px;
	}

	.titulo-foro {
		font-size: 8rem !important;
	}
	.separador-foro {
		width: 500px !important;
	}
	.svg-foro {
		font-size: 3rem !important;
	}
}
