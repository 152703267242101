
.card-images {
	margin-top: 1.5rem;
	width: 100%;
	height: 200px;
	min-height: 150px;
	border-radius: 15px;
	padding: 0;
	background: white;
	position: relative;
	display: flex;
	align-items: center;
    text-align: center;
	transition: 0.4s ease-out;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

@media (min-width: 2500px) {
	.card-images {
		height: 375px;
	}
}


.card-images:hover {
	transform: translateY(10px);
}
.card-images:hover:before {
	opacity: 1;
}
.card-images:hover .info-images {
	opacity: 1;
	transform: translateY(0px);
}
.card-images:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 15px;
	background: rgba(0, 0, 0, 0.6);
	z-index: 2;
	transition: 0.5s;
	opacity: 0;
}
.card-images img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 15px;
}
.card-images .info-images {
	padding: 0.5rem;
	position: relative;
	z-index: 3;
	color: white;
	opacity: 0;
	transform: translateY(30px);
	transition: 0.5s;
	width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-body-titulo {
	margin: 0px;
	padding-top: 1rem;
}
.card-body-descripcion {
	letter-spacing: 1px;
	font-size: 10px;
}
.card-images .info-images .tomar-ruta {
	padding: 0.4rem;
	outline: none;
	border: none;
	border-radius: 5px;
	background: white;
	color: black;
	font-weight: bold;
	transition: 0.4s ease;
	font-size: 12px;
}

@media (min-width: 2500px) {
	.card-body-descripcion {
		letter-spacing: 1px;
		font-size: 30px;
	}

	.card-images .info-images .tomar-ruta {
		font-size: 28px;
	}

	.alert-rutas {
		font-size: 2rem !important;
	}
}