.material-capacitacion{
    margin: 0 auto;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in;
}

.material-capacitacion img{
    border-radius: 10px 10px 10px 10px ;

}

.material-capacitacion:hover{
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05);
    cursor: pointer;
}


.img-capacitacion{
    margin: auto;
}