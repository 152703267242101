.card-download-plantilla {
    width: 200px;
    height: 100%;
    text-align: center;
}


.card-download-plantilla:hover {
    background-color: #eee;
    border-radius: 0.125rem;
    transform: scale(1.1);
} 