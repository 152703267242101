.contenedor-feedback {
	padding-top: 1rem;
	height: 100vh;
	width: 100%;
	background: #f1f1f1;

	overflow-y: scroll;
	overflow-x: hidden;
}

.contenedor-feedback::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.contenedor-feedback::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 4px;
}

.contenedor-feedback::-webkit-scrollbar-thumb:hover {
	background: #b3b3b3;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.contenedor-feedback::-webkit-scrollbar-thumb:active {
	background-color: #999999;
}

.contenedor-feedback::-webkit-scrollbar-track {
	background: #e1e1e1;
	border-radius: 4px;
}

.contenedor-feedback::-webkit-scrollbar-track:hover,
.contenedor-feedback::-webkit-scrollbar-track:active {
	background: #d4d4d4;
}
